@font-face {
  font-family: "Gideon Roman";
  src: url("assets/fonts/START.otf");
  font-weight: 250;
  font-style: normal;
  font-display: swap;
}
html {
  scroll-behavior: smooth;
  font-family: "Gideon Roman", ui-sans-serif, system-ui;
  overflow: overlay;
}
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.icon-1 {
  width: 27px;
  height: 27px;
  color: #ffedd5;
  cursor: pointer;
}
.icon-1:hover {
  transform: scale(1.3);
  color: #f5f5f5;
}
.icon-2 {
  width: 20px;
  height: 25px;
  color: #ffedd5;
  cursor: pointer;
}
.icon-2:hover {
  transform: scale(1.2);
  color: #c9c5c0;
}
.icon-3 {
  width: 20px;
  height: 25px;
  color: #ffedd5;
  cursor: pointer;
}
.icon-3:hover {
  color: #d3cfc9;
}

.mint-element {
  font-size: 12px;
  width: 100%;
  cursor: pointer;
  line-height: 50px;
  border-radius: 1px;
  transition: "0.3s";
  border: 3px solid #000;
  box-shadow: 5px 5px 5px #000;
  background: #018e39;
  text-align: center;
}

.mint-active {
  background: #006636;
  border: 3px solid #fff !important;
}

.mint-element:hover {
  border: 3px solid #000;
  background: #006636;
}

.menu-button {
  cursor: pointer;
  padding: 10px !important;
  background: #018e39 !important;
  border-radius: 1px !important;
  transition: 0.3s !important;
  text-transform: none !important;
  font-size: 12px !important;
  font-weight: bold !important;
  border: 3px solid #000 !important;
  box-shadow: 5px 5px 5px #000 !important;
  color: white !important;
  font-family: "Gideon Roman", ui-sans-serif, system-ui !important;
}

.menu-button:hover {
  background: #006636 !important;
  box-shadow: none !important;
}

.wallet-button {
  cursor: pointer;
  color: #78350f !important;
  background: #ffedd5 !important;
  padding: 10px !important;
  border-radius: 1px !important;
  transition: 0.3s !important;
  text-transform: none !important;
  font-size: 12px !important;
  font-weight: bold !important;
  border: 3px solid #000 !important;
  box-shadow: 5px 5px 5px #000 !important;
  font-family: "Gideon Roman", ui-sans-serif, system-ui !important;
}

.wallet-button:hover {
  background: rgb(253, 186, 116) !important;
  box-shadow: none !important;
}
